import * as React from "react"
import { Row, Col } from "react-flexbox-grid"
// import { StaticImage } from "gatsby-plugin-image"

// import AniLink from "gatsby-plugin-transition-link/AniLink"
// import { Button } from "../../Button"

import { Container } from "../../../styles/container"
import { SectionHeader, SectionContent, SectionTitle } from "../"
import { Paragraph } from "../../Text"
import SectionHero from "../SectionHero"

import * as S from "./styled"

const SectionPrivacyPolicy = () => (
  <S.Section>
    <SectionHeader>
      <SectionHero>
        <Container>
          <SectionTitle as="h1" medium noMargin colorInverse>
            Termos de Uso e Política de Privacidade
          </SectionTitle>
        </Container>
      </SectionHero>
    </SectionHeader>

    <SectionContent className="s-content">
      <Container>
        <div className="box-content">
          <Paragraph darken className="paragraph">
            Prezando pela autodeterminação informacional dos Titulares-usuários
            e uma prestação de serviços em conformidade com as normas pátrias de
            privacidade e proteção de dados, a{" "}
            <b>
              RUY MOLINA SOCIEDADE INDIVIDUAL DE ADVOCACIA, PESSOA JURIDICA,
            </b>{" "}
            inscrita no <b>CNPJ 27.391.299/0001-09</b>, com escritório à Rua
            Walfredo Macedo Brandão, 1060, Sala 104 -Jardim Cidade
            Universitária- João Pessoa-PB, elaborou o presente documento de
            acordo com o Marco Civil da Internet (Lei nº 12.965), Lei Geral de
            Proteção de Dados (Lei nº 13.709), e demais normas aplicáveis,
            visando esclarecer dúvidas sobre o funcionamento da plataforma e o
            tratamento que a plataforma virtual do escritório de advocacia Ruy
            Molina confere aos seus dados pessoais.
          </Paragraph>

          <h4 className="box-title">
            1. APLICABILIDADE DESTES TERMOS DE USO E DA POLÍTICA DE PRIVACIDADE
          </h4>

          <Paragraph darken className="paragraph">
            Desenvolvemos o presente documento em atenção à privacidade e
            proteção dos dados de nossos usuários (“você”, “titular” ou
            “cidadão”). Ao acessar ou interagir com a nossa plataforma,
            poderemos solicitar algumas informações (“dados” ou “dados
            pessoais”) sobre você. Elaboramos este documento com o intuito de
            esclarecer as funcionalidades desta plataforma e a forma como seus
            dados pessoais poderão ser coletados, usados, compartilhados e/ou
            armazenados.
          </Paragraph>

          <Paragraph darken className="paragraph">
            O aceite desta política deverá ser dado no momento do cadastro e
            implicará no reconhecimento de que você leu, entendeu e aceitou,
            incondicionalmente, todas as disposições previstas neste documento.
            Dessa forma, você estará ciente e em total acordo com o tratamento
            que conferiremos às informações e aos seus dados pessoais, depois de
            coletados. Caso não concorde com esta Política, não será possível
            realizar o seu cadastro e, portanto, utilizar esta plataforma.
          </Paragraph>

          <h4 className="box-title">2. CONCEITOS BÁSICOS</h4>

          <Paragraph darken className="paragraph">
            A fim de facilitar o seu entendimento e a compreensão dos termos ora
            dispostos, gostaríamos de esclarecer, de pronto, as seguintes
            definições que serão utilizados ao longo do documento:
          </Paragraph>

          <ul className="list -style-disc">
            <li className="item">
              Titular: pessoa física a quem os dados pessoais se referem;
            </li>
            <li className="item">
              Dado Pessoal: dados que fornecem informações sobre pessoa natural,
              identificando-a (ex. nome, CPF, endereço, outros quando atrelados
              à identidade da pessoa);
            </li>
            <li className="item">
              Tratamento: qualquer forma de utilização dos dados (ex. coleta,
              acesso; processamento, compartilhamento, reprodução, exclusão);
            </li>
            <li className="item">
              Política: nome abreviado da presente Política de Privacidade.
            </li>
            <li className="item">
              Aplicativo ou Plataforma: Solução fornecida pelo escritório de
              advocacia Ruy Molina, como meio de viabilizar o atendimento aos
              clientes de forma virtual e com extensão em todo território
              nacional.
            </li>
          </ul>

          <h4 className="box-title">3. A PLATAFORMA</h4>

          <Paragraph darken className="paragraph">
            Esta plataforma foi desenvolvida com o objetivo de facilitar o
            atendimento ao cliente em todo território nacional, com análise e
            consultas processuais administrativos e judiciais.
          </Paragraph>

          <Paragraph darken className="paragraph">
            Depois de cadastrados, nossos usuários terão acesso às seguintes
            funcionalidades:
          </Paragraph>

          <ul className="list -style-number">
            <li className="item">Agendamento de atendimento com advogado;</li>
            <li className="item">Consulta ao processo virtual;</li>
            <li className="item">
              Consulta aos locais dos escritórios mais próximos de sua
              localidade;
            </li>
            <li className="item">
              Espaço para sugestão, reclamação acerca do atendimento;
            </li>
            <li className="item">
              Espaço para blog, com assuntos cotidianos atualizados;
            </li>
            <li className="item">
              Possibilidade de registrar o processo para analise online;
            </li>
            <li className="item">
              Possibilidade para cadastro de currículo online.
            </li>
          </ul>

          <h4 className="box-title">
            4. DO TRATAMENTO DOS DADOS PESSOAIS DOS NOSSOS USUÁRIOS
          </h4>
          <Paragraph darken className="paragraph">
            Para registrar o processo online, cadastrar currículo online ou
            necessitar de um atendimento virtual, será necessário que você
            realize o seu cadastro no nosso sistema através das páginas de
            cadastro disponibilizadas. Para realizar o cadastro, nós solicitamos
            alguns dos seus dados pessoais.
          </Paragraph>
          <Paragraph darken className="paragraph">
            Esta plataforma foi desenvolvida com o objetivo de facilitar o
            atendimento ao cliente em todo território nacional, com análise e
            consultas processuais administrativos e judiciais. A seguir,
            explicamos a finalidade da coleta dos dados pessoais solicitados.
          </Paragraph>

          <h4 className="box-title">
            4.1. CADASTRO DO TITULAR NA PLATAFORMA E FINALIDADE DOS DADOS
            PESSOAIS COLETADOS.
          </h4>
          <Paragraph darken className="paragraph">
            Para realizar o seu cadastro na nossa plataforma, nós solicitamos os
            seguintes dados pessoais:
          </Paragraph>

          <ul className="list -style-disc">
            <li className="item">Nome completo; *</li>
            <li className="item">CPF;</li>
            <li className="item">Data de Nascimento; *</li>
            <li className="item">Telefone Celular; *</li>
            <li className="item">E-mail;</li>
            <li className="item">Endereço Completo;</li>
          </ul>

          <small>* Dados obrigatorios</small>

          <Paragraph darken className="paragraph">
            Os dados coletados são usados para a configuração e o gerenciamento
            das contas de usuário, como na criação de contas, no login no app ou
            na verificação de credenciais pelo usuário, os dados tambem são
            usado para personalizar a plataforma e também podem ser usados para
            exibir ou segmentar anúncios ou comunicados de marketing.
          </Paragraph>

          <h4 className="box-title">
            4.2. CADASTRO DE PROCESSO NA PLATAFORMA E FINALIDADE DOS DADOS
            PESSOAIS COLETADOS.
          </h4>
          <Paragraph darken className="paragraph">
            Para realizar o seu cadastro de um processo em nossa plataforma, nós
            solicitamos os seguintes dados pessoais:
          </Paragraph>

          <ul className="list -style-disc">
            <li className="item">Nome completo; *</li>
            <li className="item">CPF;</li>
            <li className="item">Data de Nascimento; *</li>
            <li className="item">Telefone Celular; *</li>
            <li className="item">E-mail;</li>
            <li className="item">Endereço Completo;</li>
          </ul>

          <Paragraph darken className="paragraph">
            Documentos exclusivos a depender da área de atuação:
          </Paragraph>

          <ul className="list -style-disc">
            <li className="item">Carteira de trabalho;</li>
            <li className="item">CNIS;</li>
            <li className="item">Laudos médicos;</li>
            <li className="item">Contato de trabalho;</li>
            <li className="item">
              Carta de indeferimento no Instituto Nacional de Seguro Social;
            </li>
            <li className="item">Outros pertinentes a análise processual.</li>
          </ul>

          <small>* Dados obrigatorios</small>

          <Paragraph darken className="paragraph">
            Os dados coletados são usados para realizar uma triagem e para um
            melhor entendimento do seu processo. Os mesmos dados também podem
            ser usados para dá entrada com o processo na justiça mas apenas com
            a sua autorizaçãoprevia.
          </Paragraph>

          <h4 className="box-title">
            5. DÚVIDAS, SOLICITAÇÕES DO TITULAR E CONTATO DO ENCARREGADO
            (“DPO”).
          </h4>

          <Paragraph darken className="paragraph">
            Caso você, titular de dados pessoais e usuário da nossa plataforma,
            precise de uma cópia das suas informações, as solicitações deverão
            ser realizadas através do canal da ouvidoria do Escritório através
            do número telefone: 0800 252 1600, totalmente gratuito, bem como
            disponibilizamos o mesmo número para WhatsApp.
          </Paragraph>

          <Paragraph darken className="paragraph">
            Para fins de esclarecimento, listamos os seus direitos como titular
            de dados pessoais no tópico 7 da presente política.
          </Paragraph>

          <h4 className="box-title">
            6. MEDIDAS DE SEGURANÇA E INFORMAÇÕES ADICIONAIS
          </h4>
          <Paragraph darken className="paragraph">
            A sua segurança é a nossa prioridade. Posto isso, listamos todas as
            medidas de segurança e mitigação de riscos que adotamos para
            assegurar o ambiente mais seguro possível aos nossos usuários:
          </Paragraph>

          <ul className="list -style-alphabetic">
            <li className="item">
              Criptografia dos dados fornecidos: após o fornecimento dos seus
              dados, estes serão criptografados;
            </li>
            <li className="item">
              Uso de tecnologia cloud adequada às principais legislações de
              proteção de dados do mundo: nós utilizamos a tecnologia em nuvem
              da Heroku para armazenar os seus dados. A Heroku é uma das nuvens
              mais seguras da atualidade, e está em conformidade com as
              principais legislações de proteção de dados do mundo, dentre elas
              a LGPD – Lei Geral de Proteção de Dados do Brasil – e a GRPD –
              General Data Protection Regulation, legislação de proteção de
              dados europeia -.
            </li>
            <li className="item">
              Autenticação dois fatores para acessar a nuvem: com o objetivo de
              adicionar mais uma camada de segurança ao acesso dos seus dados
              pessoais, se faz necessária a combinação de dois componentes para
              que os nossos colaboradores consigam acessar às informações na
              nuvem.
            </li>
            <li className="item">
              Colaboradores vinculados à obrigação de confidencialidade: todos
              os nossos colaboradores e prestadores de serviços são vinculados à
              obrigação de confidencialidade de todas as informações a que tiver
              acesso por meio de assinatura de Termo de Confidencialidade.
            </li>
          </ul>

          <h4 className="box-title">7. ALTERAÇÕES DA POLÍTICA</h4>
          <Paragraph darken className="paragraph">
            Estamos constantemente trabalhando para aprimorar os serviços
            prestados. Conforme ocorram mudanças na oferta deste serviço ou nas
            legislações aplicáveis, esta Política poderá sofrer alterações.
            Dessa forma, recomendamos a visita periódica a este documento que
            estará sempre disponível em nossa plataforma.
          </Paragraph>

          <h4 className="box-title">
            8. DIREITOS DOS TITULARES DE DADOS PESSOAIS COM BASE NA LGPD.
          </h4>
          <Paragraph darken className="paragraph">
            A Lei Geral de Proteção de Dados traz uma série de diretrizes sobre
            o tratamento de dados pessoais, bem como dispõe sobre os direitos
            dos titulares. Nessa linha, listamos abaixo os direitos que você,
            titular de dados pessoais, têm com base no artigo 18 da referida
            legislação:
          </Paragraph>

          <Row className="table-header">
            <Col xs={5} sm={4}>
              <h5 className="box-title -v2">Seus direitos</h5>
            </Col>
            <Col xs={7} sm={8}>
              <h5 className="box-title -v2">Explicação</h5>
            </Col>
          </Row>

          <Row>
            <Col xs={5} sm={4}>
              <h6 className="box-title -v2">Confirmação e acesso</h6>
            </Col>
            <Col xs={7} sm={8}>
              <Paragraph darken className="paragraph">
                Você pode solicitar à Empresa a confirmação sobre a existência
                de tratamento dos seus Dados Pessoais para que, em caso
                positivo, você possa acessá-los, inclusive por meio de
                solicitação de cópias dos registros que temos sobre você.
              </Paragraph>
            </Col>
          </Row>

          <Row>
            <Col xs={5} sm={4}>
              <h6 className="box-title -v2">Correção</h6>
            </Col>
            <Col xs={7} sm={8}>
              <Paragraph darken className="paragraph">
                Você pode solicitar a correção dos seus Dados Pessoais caso
                estes estejam incompletos, inexatos ou desatualizados.
              </Paragraph>
            </Col>
          </Row>

          <Row>
            <Col xs={5} sm={4}>
              <h6 className="box-title -v2">
                Anonimização, bloqueio ou eliminação
              </h6>
            </Col>
            <Col xs={7} sm={8}>
              <Paragraph darken className="paragraph">
                Você pode solicitar <b>(a)</b> a anonimização dos seus Dados
                Pessoais, de forma que eles não possam mais ser relacionados a
                você e, portanto, deixem de ser Dados Pessoais; <b>(b)</b> o
                bloqueio dos seus Dados Pessoais, suspendendo temporariamente a
                sua possibilidade de os tratarmos para certas finalidades; e{" "}
                <b>(c)</b> a eliminação dos seus Dados Pessoais, caso em que
                deveremos apagar todos os seus Dados Pessoais sem possibilidade
                de reversão.
              </Paragraph>
            </Col>
          </Row>

          <Row>
            <Col xs={5} sm={4}>
              <h6 className="box-title -v2">Portabilidade</h6>
            </Col>
            <Col xs={7} sm={8}>
              <Paragraph darken className="paragraph">
                Você pode solicitar que a Empresa forneça os seus Dados Pessoais
                em formato estruturado e interoperável visando à sua
                transferência para um terceiro, desde que essa transferência não
                viole a propriedade intelectual ou segredo de negócios da
                Empresa.
              </Paragraph>
            </Col>
          </Row>

          <Row>
            <Col xs={5} sm={4}>
              <h6 className="box-title -v2">
                Informação sobre o compartilhamento
              </h6>
            </Col>
            <Col xs={7} sm={8}>
              <Paragraph darken className="paragraph">
                Você tem o direito de saber quais são as entidades públicas e
                privadas com as quais a Empresa realiza uso compartilhado dos
                seus Dados Pessoais. Em todo caso, se você tiver dúvidas ou
                quiser mais detalhes, você tem o direito de nos solicitar essas
                informações. A depender do caso, podemos limitar as informações
                fornecidas a você, caso a sua divulgação possa violar a
                propriedade intelectual ou segredo de negócios da Empresa.
              </Paragraph>
            </Col>
          </Row>

          <Row>
            <Col xs={5} sm={4}>
              <h6 className="box-title -v2">
                Informação sobre a possibilidade de não consentir
              </h6>
            </Col>
            <Col xs={7} sm={8}>
              <Paragraph darken className="paragraph">
                Você tem o direito de receber informações claras e completas
                sobre a possibilidade e as consequências de não fornecer
                consentimento, quando ele for solicitado pela Empresa. O seu
                consentimento, quando necessário, deve ser livre e informado.
                Portanto, sempre que pedirmos seu consentimento, você será livre
                para negá-lo – nesses casos, é possível que alguns serviços não
                possam ser prestados.
              </Paragraph>
            </Col>
          </Row>

          <Row>
            <Col xs={5} sm={4}>
              <h6 className="box-title -v2">Revogação do consentimento</h6>
            </Col>
            <Col xs={7} sm={8}>
              <Paragraph darken className="paragraph">
                Caso você tenha consentido com alguma finalidade de tratamento
                dos seus Dados Pessoais, você pode sempre optar por retirar o
                seu consentimento. No entanto, isso não afetará a legalidade de
                qualquer Tratamento realizado anteriormente à revogação. Se você
                retirar o seu consentimento, é possível que fiquemos
                impossibilitados de lhe prestar certos serviços, mas iremos
                avisá-lo quando isso ocorrer.
              </Paragraph>
            </Col>
          </Row>

          <Row>
            <Col xs={5} sm={4}>
              <h6 className="box-title -v2">Oposição</h6>
            </Col>
            <Col xs={7} sm={8}>
              <Paragraph darken className="paragraph">
                A lei autoriza o tratamento de Dados Pessoais mesmo sem o seu
                consentimento ou um contrato conosco. Nessas situações, somente
                trataremos seus Dados Pessoais se tivermos motivos legítimos
                para tanto, como, por exemplo, quando for necessário para
                garantir a segurança de nossas rodovias. Caso você não concorde
                com alguma finalidade de tratamento dos seus Dados Pessoais,
                você poderá apresentar oposição, solicitando a sua interrupção.
              </Paragraph>
            </Col>
          </Row>

          <Paragraph darken className="paragraph -extra-mt">
            Desde já esclarecemos que nem sempre poderemos executar a
            solicitação feita, uma vez que temos obrigações legais a serem
            cumpridas. Desta forma, eventual exercício destes direitos, como por
            exemplo a revogação de consentimentos, poderá afetar a sua
            capacidade de utilizar alguns de nossos produtos ou serviços e/ou
            algumas de suas funcionalidades e recursos, além de prejudicar a
            nossa capacidade de lhe oferecer a melhor experiência como usuário.
            Dentro dos limites da legislação aplicável, a Plataforma se reserva
            o direito de manter a confidencialidade de algumas informações e
            dados, a fim de preservar o sigilo comercial, por obrigação legal
            ou, até mesmo, para proteger os direitos de seus clientes e
            titulares de dados pessoais.
          </Paragraph>

          <h4 className="box-title">9. LEI APLICÁVEL E FORO COMPETENTE</h4>

          <Paragraph darken className="paragraph">
            Esta <b>Política de Privacidade</b> será regida e executada conforme
            o ordenamento jurídico do Brasil, com especial atenção ao Marco
            Civil da Internet (Lei nº 12.965) e à Lei Geral de Proteção de Dados
            (Lei nº 13.709). O Foro Competente para dirimir eventuais lides
            relacionadas a esta Política de Privacidade é o foro da comarca de
            João Pessoa/PB.
          </Paragraph>
        </div>
      </Container>
    </SectionContent>
  </S.Section>
)

export default SectionPrivacyPolicy
