import styled from "styled-components"

// import { breakpoints, sizes } from "../../../styles/breakpoints"

import { Section as SectionComponent } from "../"

export const Section = styled(SectionComponent)`
  padding: 0 0 100px;

  .s-content {
    margin-top: 50px;

    .box-content {
      color: var(--color-grayscale6);
      max-width: 900px;

      .box-title {
        text-transform: uppercase;
        font-weight: normal;
        margin: 35px 0 20px;
        color: var(--color-black);

        &.-v2 {
          text-transform: none;
          font-weight: 800;
          margin: 10px 0 10px;
          font-size: 1rem;
        }
      }

      .paragraph {
        line-height: 1.5;
        &:not(:last-of-type) {
          margin-bottom: 20px;
        }

        &.-extra-mt {
          margin-top: 20px;
        }

        b {
          font-weight: 800;
        }
      }

      .list {
        .item {
          font-weight: normal;
          margin-left: 40px;
          line-height: 1.2;
          &:not(:last-of-type) {
            margin-bottom: 8px;
          }
        }

        & + small {
          display: block;
          margin-top: 20px;
          & + .paragraph {
            margin-top: 20px;
          }
        }

        & + .paragraph {
          margin-top: 20px;
        }

        &.-style-disc {
          list-style: disc;
        }

        &.-style-number {
          list-style: decimal;
        }

        &.-style-alphabetic {
          list-style: lower-alpha;
        }
      }

      .table-header {
        margin-bottom: 15px;
      }
    }
  }
`
