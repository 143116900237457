import * as React from "react"

import Seo from "../components/seo"
import Layout from "../components/Layout"
import SectionPrivacyPolicy from "../components/Section/SectionPrivacyPolicy"
import WppButtonFloat from "../components/Button/WppButtonFloat"

const PrivacyPolicyPage = () => (
  <Layout>
    <Seo title="Política de privacidade" />
    <SectionPrivacyPolicy />
    <WppButtonFloat />
  </Layout>
)

export default PrivacyPolicyPage
